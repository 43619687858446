<template>
  <div>
    <div class="search">
        <span class="el-span">病案号：</span>
        <el-input class="el-input" maxlength="7" v-model="recordNo" placeholder="请输入病案号" oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
      <el-button type="primary" style="width:120px;" @click="onSearch">查询</el-button>
    </div>
    <table-comp
      :table-opts="tableOpts"
      :data="tableData"
      :page="currentPage"
      :total="totalPage"
      :page-size="pageSize"
      :show-number="true"
      :open-handle="true"
      :handle-opts="handleOpts"
      @handleSizeChange="sizeChange"
      @handleCurrentChange="pageChange"
    >
      <template #btn_1="{ msg }">
        <el-button style="font-size: 14px;" size="mini" type="text" @click="other_1(msg)">患者档案</el-button>
      </template>
    </table-comp>
  </div>
</template>

<script>
import TableComp from '@/components/Table/Table'
// import { getFollowUpList, getFollowUpCycleList } from '@/service/module/followup'
import { getPatientList } from '@/service/module/enterpatient'
export default {
  components: {
    TableComp
  },
  data () {
    return {
      recordNo: '',
      searchOpts: [
        {
          label: '病案号：',
          key: 'recordNo',
          placeholder: '请输入病案号',
          type: 'input'
        }
      ],
      searchData: {
        input: '',
        seqNo: '',
        periodStatus: ''
      },
      tableOpts: [ // 列表表头设置
        {
          label: '患者姓名',
          prop: 'name',
          render: (h, params) => {
            const name = params.row.name
            const nameText = '-'
            if (!name) {
              return h('span', {}, nameText)
            } else {
              return h('span', {}, name)
            }
          }
        },
        {
          label: '病案号',
          prop: 'medicalRecordNumber',
          render: (h, params) => {
            const recordNo = params.row.medicalRecordNumber
            const recordNoText = '-'
            if (!recordNo) {
              return h('span', {}, recordNoText)
            } else {
              return h('span', {}, recordNo)
            }
          }
        },
        {
          label: '性别',
          prop: 'gender',
          render: (h, params) => {
            const gender = params.row.gender
            let genderText = ''
            switch (gender) {
              case '2' : genderText = '女'
                break
              case '1' : genderText = '男'
                break
            }
            return h('span', {}, genderText)
          }
        },
        {
          label: '年龄',
          prop: 'age',
          render: (h, params) => {
            const age = params.row.age
            const ageText = '-'
            if (!age) {
              return h('span', {}, ageText)
            } else {
              return h('span', {}, age)
            }
          }
        },
        {
          label: '手机号',
          prop: 'phone',
          width: 162,
          render: (h, params) => {
            const phone = params.row.phone
            const phoneText = '-'
            if (!phone) {
              return h('span', {}, phoneText)
            } else {
              return h('span', {}, phone)
            }
          }
        },
        {
          label: '身份证号码',
          prop: 'certificateNo',
          render: (h, params) => {
            const certificateNo = params.row.certificateNo
            const certificateNoText = '-'
            if (!certificateNo) {
              return h('span', {}, certificateNoText)
            } else {
              return h('span', {}, certificateNo)
            }
          }
        },
        {
          label: '注册时间',
          prop: 'createAt',
          render: (h, params) => {
            var moment = require('moment')
            const implantDate = moment.unix(params.row.createAt / 1000).format('Y-MM-DD HH:mm:ss')
            return h('span', {}, implantDate)
          }
        }
      ],
      handleOpts: {
      },

      value: null,

      tableData: [],
      totalPage: 0, // 总条数
      currentPage: 1, // 当前条数
      pageSize: 20 // 每页条数
    }
  },
  mounted () {
    this.getPatientList(1, this.pageSize)
  },
  methods: {
    splicingString (list) {
      let str = ''
      list.forEach(i => {
        str += i.text + '<br/>'
      })
      return str
    },
    async getFollowUpList (pageNum, pageSize) {
      try {
        this.searchData.pageNum = pageNum
        this.searchData.pageSize = pageSize
        // const res = await getFollowUpList(
        //   this.searchData)
        // if (res) {
        //   this.tableData = res.data.results
        //   this.totalPage = res.data.total
        //   this.currentPage = res.data.currentPage
        //   this.pageSize = res.data.pageSize
        // }
      } catch (e) {
        console.log('awaitList-err:', e)
      }
    },
    async getPatientList (pageNum, pageSize) {
      getPatientList({
        pageNum: pageNum,
        pageSize: pageSize,
        recordNo: this.recordNo
      }).then(res => {
        if (res.data) {
          this.tableData = res.data.results
          this.totalPage = res.data.total
        }
      })
    },
    other_1 (data) {
      this.$router.push({
        name: 'patientFiles',
        query: {
          patientId: data.id
        }
      })
    },
    sizeChange (val) {
      this.pageSize = val
      this.getPatientList(this.currentPage, val)
    },
    pageChange (val) {
      this.currentPage = val
      this.getPatientList(val, this.pageSize)
    },
    onSearch () {
      this.getPatientList(1, this.pageSize, this.recordNo)
    }
  }
}
</script>

<style scoped lang="scss">
.search {
  float: right;
  margin: 20px 0 12px 20px;
  .el-input {
   width: 280px;
   line-height: 40px;
   margin-right: 24px;
  }
  .el-span {
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
  }
}
</style>
